/* global gon */

import "./periph_air_component.scss";
import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["navLeft", "navRight", "report"];

  connect() {
    this.paData = gon.periph_air;
    this.paDataLength = this.paData.reports.length - 1;

    this.currentSlideIndex = this.paDataLength;
    this.currentSlide = this.reportTargets[this.currentSlideIndex];

    this.currentSlide.classList.remove("d-none");

    if (this.paData.reports.length === 1) {
      this.navLeftTarget.classList.add("disabled");
    }
  }

  prev(e) {
    if (e.target.classList.contains("disabled")) return;
    if (this.currentSlideIndex !== 0) {
      this.currentSlideIndex--;

      this._toggleStatus();
    }
    this._updatePeriphAirData();
  }

  next(e) {
    if (e.target.classList.contains("disabled")) return;

    if (this.currentSlideIndex !== this.paDataLength) {
      this.currentSlideIndex++;

      this._toggleStatus();
    }

    this._updatePeriphAirData();
  }

  _updatePeriphAirData() {
    this.reportTargets.forEach((item, index) => {
      if (index === this.currentSlideIndex) {
        item.classList.remove("d-none");
      } else {
        item.classList.add("d-none");
      }
    });
  }

  _toggleStatus() {
    if (this.currentSlideIndex === 0) {
      this.navLeftTarget.classList.add("disabled");
      if (this.paData.reports.length > 1) this.navRightTarget.classList.remove("disabled");
    } else {
      this.navLeftTarget.classList.remove("disabled");
      this.navRightTarget.classList.toggle("disabled", this.currentSlideIndex === this.paDataLength);
    }
  }
}
